// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react" 
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const orderApi = createApi({
    reducerPath:"orderApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder)=>({
       getOrders:builder.query({
           query: ({ requiredDate, pageNo, pageSize, orderDate, order_id, transaction_id ,status})=>({
               url: `/get-all-order?requiredDate=${requiredDate}&orderDate=${orderDate}&order_id=${order_id}&transaction_id=${transaction_id}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`,
               method: "GET",
           }),
           providesTags: ["orders"]
       }),
        updatePaymentStatus: builder.mutation({
            query: (credentials) => ({
                url: "/update-payment-status",
                method: "PUT",
                body:credentials
            }),
            invalidatesTags: ['orders']

        }),
        updatePaymentBill: builder.mutation({
            query: (credentials) => ({
                url: "/update-payment-bill",
                method: "PUT",
                body:credentials
            }),
            invalidatesTags: ['orders']

        }),
        updateOrderStatus: builder.mutation({
            query: (credentials) => ({
                url: "/update-order-status",
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateRequiredDate: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-required-date`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateOrderRecievedDate: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-recieve-date`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateOrderRecievedQty: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-recieve-qty`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateOrderRecievedFd: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-recieve-fd`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateOrderRecievedBalanceStock: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-balance-stock`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),
        updateOrderVehicleNo: builder.mutation({
            query: (credentials) => ({
                url: `/update-order-vehicle-no`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ['orders']
        }),

    }),

})

export const {
    useGetOrdersQuery,
    useLazyGetOrdersQuery,
    useUpdatePaymentStatusMutation,
    useUpdatePaymentBillMutation,
    useUpdateOrderStatusMutation,
    useUpdateRequiredDateMutation,
    useUpdateOrderRecievedBalanceStockMutation,
    useUpdateOrderRecievedDateMutation,
    useUpdateOrderRecievedFdMutation,
    useUpdateOrderRecievedQtyMutation,
    useUpdateOrderVehicleNoMutation
} = orderApi