
// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react" 
import { baseQuery, baseQueryHeaders,baseUrl } from "./helper";
// Define a service using a base URL and expected endpoints
export const uploadApi = createApi({
    reducerPath:"uploadApi",
    baseQuery: fetchBaseQuery({
        baseUrl:baseUrl,
        prepareHeaders: (headers,{getState}) => {
            headers.set("Content-Type", "multipart/form-data boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW");
            return headers
        }
    }),
    endpoints: (builder)=>({
        upload:builder.mutation({
            query:(file)=>{
                return({
                
                url:"/upload",
                method:"POST",
                body:file
            })}
        }),
        
    }),
})
export const {useUploadMutation} = uploadApi