export const userRole = {
    super: 0,
    admin: 1,
    staff: 3,
    dealer: 4,
    customer: 5,
}

export const superuserRoleOptions = [
    {
        value: userRole.admin,
        label: 'Admin',
    },
    {
        value: userRole.staff,
        label: 'Staff',
    },
];
//changes

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const panNoRefExp = /^[0-9]{9,}$/;

export const ORDER_STATUS = ["PENDING", "CONFIRMED","NOT_FULL_DEMAND","DELIVERED", "CANCELLED"]
