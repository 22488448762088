import {toast} from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
// set data into local storage
export const setLocalStorage = (key, value) => {
  return window.localStorage.setItem(key, JSON.stringify(value));
}
// get data from local storage
export const getLocalStorage = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
}


export const clearLocalStorage = (key) => {
    return window.localStorage.removeItem(key);
}

// export const random

export const successToast = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
}

export const errorToast = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT
  });
}
export const warningToast = (message) => {
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT
  });
}

export const infoToast = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT
  });
}

// confirmation alert modal

export const confirmAlertModal = (message, callback) => {
  confirmAlert({
    title: 'Confirm?',
    message: message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => callback(true)
      },
      {
        label: 'No',
        onClick: () => callback(false)
      }
    ]
  })
}

export const exportExcel = ({ excelData, fileName }) => {
  const filetype = 'application/vmd.openxmlformats-officedocument.spreadsheetmt.sheet;charset=UTF-8';
  const fileExtension = '.xlsx'
  const exportToEXcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileName })
    FileSaver.saveAs(data,fileName + fileExtension)
  }
  return exportToEXcel()
}