// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react" 
import { baseQuery, baseQueryHeaders } from "./helper";
// Define a service using a base URL and expected endpoints
export const authApi = createApi({
    reducerPath:"auth",
    baseQuery: baseQuery,
    endpoints: (builder)=>({
        login:builder.mutation({
            query:(credentials)=>({
                url:"/login-user",
                method:"POST",
                body:credentials
            })
        }),
    }),

})

export const {useLoginMutation} = authApi