
import 'react-toastify/dist/ReactToastify.css';// Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { BrowserRouter } from "react-router-dom"
import ZetasoftLayout from './ZetasoftLayout';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AuthGuard from './AuthGuard';
import Authentication from './Authentication';
import {  toast } from 'react-toastify';

toast.configure()
const App = () => {
    
    return (

            <Provider store={store}>
                <Authentication>
                    <BrowserRouter>
                        <AuthGuard>
                            <ZetasoftLayout />
                        </AuthGuard>
                    </BrowserRouter>
                </Authentication>
            </Provider>
       
    )
}


export default App;