// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["Customer", "dealer", "adminUsers", "staffUsers", "users", 'organisation'],
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            query: () => ({
                url: "/get-admin-user",
                method: "GET"
            }),
            providesTags: ["adminUsers"]
        }),
        getStaffUsers: builder.query({
            query: () => ({
                url: "/get-staff-user",
                method: "GET"
            }),
            providesTags: ["staffUsers"]
        }),
       
        register: builder.mutation({
            query: (credentials) => ({
                url: "/register-user",
                method: "POST",
                body: credentials
            }),
            invalidatesTags: ["adminUsers", "staffUsers"]
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/delete-user-by-id?id=${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["adminUsers", "staffUsers"]
        }),
        getUserById: builder.query({
            query: (id) => ({
                url: `/get-user-by-id?id=${id}`,
                method: "GET"
            }),
            providesTags: ["users"]
        }),
        updateUserById: builder.mutation({
            query: (credentials) => ({
                url: `/update-user-profile?id=${credentials.id}`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ["users"]
        }),
        updateUserAvatar: builder.mutation({
            query: (credentials) => ({
                url: `/update-user-avatar?id=${credentials.id}`,
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ["users"]
        }),
        updateOrganisationId: builder.mutation({
            query: (credentials) => {
                
                return ({
                    url: `/update-user-organisation-id`,
                    method: "PUT",
                    body: credentials
                })
            },
            invalidatesTags: ['organisation']

        }),
        createDealer: builder.mutation({
            query: (credentials) => ({
                url: "/create-dealer",
                method: "POST",
                body: credentials
            }),
            invalidatesTags: ["dealer"]
        }),
        updateDealerDetails: builder.mutation({
            query: (credentials) => ({
                url: "/update-dealer",
                method: "PUT",
                body: credentials
            }),
            invalidatesTags: ["dealer"]
        }),
        getDealerList: builder.query({
            query: () => ({
                url: "/get-dealer-list",
                method: "GET"
            }),
            providesTags: ["dealer"]
        }),
        getDealerDetailById: builder.query({
            query: (id) => ({
                url: `/get-dealer-detail-by-id?id=${id}`,
                method: "GET"
            }),
        }),
        updateDealerStatus: builder.query({
            query: (credentials) => ({
                url: `/update-organisation`,
                method: 'PUT',
                body:credentials
            })
        }),
        deleteDealer: builder.mutation({
            query: (id) => ({
                url: `/delete-dealer-by-id?id=${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["dealer"]
        }),
        updateUserPassword: builder.mutation({
            query: (credentials) => ({
                url: `/update-user-password`,
                method: "PUT",
                body: credentials
            }),

        }),

        getAboutOrganisations: builder.query({
            query: () => ({
                url: `/get-all-organisation`,
                method: 'GET'
            }),
            providesTags: ["organisation"]
        }),
        createOrganisation: builder.mutation({
            query: (credentials) => ({
                url: `/create-organisation`,
                method: "POST",
                body: credentials
            }),
            invalidatesTags: ["organisation"]

        }),
        updateOrganisation: builder.mutation({
            query: (credentials) => ({
                url: `/update-organisation`,
                method: 'PUT',
                body:credentials
            }),
            invalidatesTags: ["organisation"]
        })

    }),

})

export const {
    useGetAllUsersQuery,
    useRegisterMutation,
    useDeleteUserMutation,
    useGetUserByIdQuery,
    useUpdateUserByIdMutation,
    useUpdateOrganisationIdMutation,
    useCreateDealerMutation,
    useUpdateDealerDetailsMutation,
    useGetDealerListQuery,
    useGetDealerDetailByIdQuery,
    useDeleteDealerMutation,
    useUpdateUserPasswordMutation,
    useGetStaffUsersQuery,
    useUpdateUserAvatarMutation,
    useGetAboutOrganisationsQuery,
    useLazyGetAboutOrganisationsQuery,
    useCreateOrganisationMutation,
    useUpdateOrganisationMutation
} = userApi