import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';
import { DashboardLayout } from '../components/dashboard-layout';
import { Route, Routes ,useLocation} from 'react-router-dom';
import routes from './routeConfig';
import React from 'react';
import PageLoader from '../components/page-loader';

const ZetasoftLayout = ({}) => {
    const location = useLocation();
    // console.log(location);
    const match = (location.pathname === "/login") || (location.pathname === "/404") || (location.pathname === "/Szs919900") || (location.pathname === "/Fzs918800") ? true : false;
    const showLayout = match ? false : true;
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <DashboardLayout showLayout={showLayout}>
                <Routes>
                    {
                        routes.map((route,i )=> (
                            <Route
                             key={i} 
                             path={route.path} 
                             element={
                                 <React.Suspense fallback={<PageLoader/>}>
                                        <route.element />
                                    </React.Suspense>
                                }/>
                        )
                        )

                    }
                </Routes>
             
            </DashboardLayout>
        </ThemeProvider>
    );
}

export default ZetasoftLayout;