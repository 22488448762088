// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const connectIpsApi = createApi({
    reducerPath: "connectIps",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["banners"],
    endpoints: (builder) => ({
        getPaymentIps: builder.query({
            query: (ip) => ({
                url: `get-payment-token-ips`,
                method: "GET",
            }),
        }),

    }),

})

export const { useGetPaymentIpsQuery } = connectIpsApi