import React from 'react';
import { Navigate } from 'react-router-dom';
const  FailurePage = React.lazy(() => import( '../pages/FailurePage'));
const ChatApp = React.lazy(() => import('../pages/chatApp'))
const UpdateDealer = React.lazy(()=>import('../pages/updateDealer')) ;
const DashBoardPage = React.lazy(() => import('../pages/Dashboard'));
const NotFound = React.lazy(() => import('../pages/404'));
const AccountPage = React.lazy(() => import('../pages/account'));
const LoginPage = React.lazy(() => import('../pages/login'));
const RegisterPage = React.lazy(() => import('../pages/register'));
const DealerPage = React.lazy(() => import('../pages/dealers'));
const SettingPage = React.lazy(() => import('../pages/settings'));
const OrdersPage = React.lazy(() => import('../pages/ordersList'));
const AdminUsersPage = React.lazy(() => import('../pages/adminUsers'));
const StaffUsersPage = React.lazy(() => import('../pages/staffUsers'));
const BannerPage = React.lazy(() => import('../pages/banner'));
const GalleryPage = React.lazy(() => import('../pages/gallery'));
const SuccessPage = React.lazy(() => import("../pages/successPage"))
const Organisations = React.lazy(() => import("../pages/organisations"))
const Vehicles = React.lazy(() => import("../pages/vehicles"))
const Inventory = React.lazy(()=>import("../pages/inventoty"))
const PageRoutes =[


    {
        path: '/',
        name: 'Dashboard',
        element: () => <Navigate to="/dealers" />
    },
    {
        path: '/organisation',
        name: 'Organisation',
        element:()=><Organisations/>

    },
    {
        path: '/404',
        name: '404',
        element: () => <NotFound />
    },
    {
        path: '/account',
        name: 'Account',
        element: () => <AccountPage />

    },
    {
        path: '/login',
        name: 'Login',
        element: () => <LoginPage />
    },
    {
        path: '/add-dealer',
        name: 'Add Dealer',
        element: () => <RegisterPage />
    },
    {
        path: '/dealers',
        name: 'Dealers',
        element: () => <DealerPage />
    },
    {
        path: '/update-user',
        name: 'Update User',
        element: () => <RegisterPage />
    },
    {
        path:"/update-dealer",
        name:"Update Dealer",
        element:()=> <UpdateDealer />
    },
    {
        path:"/settings",
        name:"Setting",
        element:()=> <SettingPage />
    },
    {
        path:"/orders",
        name:"Orders",
        element:()=> <OrdersPage />
    },
    {
        path: '/admin',
        name: 'Admin',
        element: () => <AdminUsersPage />
    },
    {
        path: '/staff',
        name: 'Staff',
        element: () => <StaffUsersPage />
    },
    {
        path: '/banner',
        name: 'Banner',
        element: () => <BannerPage />
    },
    {
        path: '/gallery',
        name: 'Gallery',
        element: () => <GalleryPage />
    },
    {
        path: '/chatApp',
        name: 'Chat app',
        element:()=><ChatApp/>
    },
    {
        path: '/Szs919900',
        name: "Success Page",
        element:()=><SuccessPage/>
    },
    {
        path: '/Fzs918800',
        name: "Failure Page",
        element:()=><FailurePage/>
    },
    {
        path: '/vehicles',
        name: 'Vehicle Page',
        element:()=><Vehicles/>
    },
    {
        path: '/inventory',
        name: 'Inventory Page',
        element:()=><Inventory/>
    }


]

const routes = [
    ...PageRoutes,
    {
        path:"*",
        element: () => <NotFound />
    }
]

export default routes;