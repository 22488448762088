import { CircularProgress,Box } from "@mui/material";
const PageLoader = () => (
    <Box
        sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)"
        }}
    >
        <CircularProgress />
    </Box>
)
export default PageLoader;