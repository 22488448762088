
import { rootReducer } from "./rootReducers"
import {configureStore} from "@reduxjs/toolkit"
import { authApi } from "./api/authApi"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { userApi } from "./api/userApi"
import { orderApi } from "./api/orderApi"
import { uploadApi } from "./api/uploadApi"
import { bannerApi } from "./api/bannerApi"
import { galleryApi } from "./api/galleryApi"
import { connectIpsApi } from "./api/connectIpsApi"
import { vehicleApi } from "./api/vehicleApi"
import {inventoryApi} from "./api/inventoryApi"
export const store = configureStore({
    reducer: {
        ...rootReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [uploadApi.reducerPath]: uploadApi.reducer,
        [bannerApi.reducerPath]: bannerApi.reducer,
        [galleryApi.reducerPath]: galleryApi.reducer,
        [connectIpsApi.reducerPath]: connectIpsApi.reducer,
        [vehicleApi.reducerPath]: vehicleApi.reducer,
        [inventoryApi.reducerPath]:inventoryApi.reducer
    },
   
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([authApi.middleware,
        userApi.middleware,
        orderApi.middleware,
        uploadApi.middleware,
        bannerApi.middleware,
        galleryApi.middleware,
        connectIpsApi.middleware,
        vehicleApi.middleware,
        inventoryApi.middleware
    ]),
})
setupListeners(store.dispatch)
export const RootState = store.getState()
