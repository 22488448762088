// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react" 
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const galleryApi = createApi({
    reducerPath:"galleryApi",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["gallery"],
    endpoints: (builder)=>({
        createGallery:builder.mutation({
            query:(gallery)=>({
                url:"/create-gallery",
                method:"POST",
                body:gallery
            }),
            invalidatesTags:["gallery"]
        }),
        getAllGallery:builder.query({
            query:(galleryFor)=>({
                url:`/get-all-gallery?galleryFor=${galleryFor}`,
                method:"GET",
            }),
            providesTags:["gallery"]
        }),
        deleteGallery:builder.mutation({
            query:(id)=>({
                url:`/delete-gallery?id=${id}`,
                method:"DELETE",
            }),
            invalidatesTags:["gallery"]
        }),
    }),

})

export const {
    useCreateGalleryMutation,
    useGetAllGalleryQuery,
    useDeleteGalleryMutation,

} = galleryApi