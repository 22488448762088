import { useSelector, useDispatch } from "react-redux";
import { getLocalStorage } from "./utils/methods";
import { useEffect,useState } from "react";
import { authSuccess, logout } from "./redux/reducers/userSlice";
import { ToastContainer } from 'react-toastify';
import PageLoader from "./components/page-loader";
const Authentication = (props) => {
    const dispatch = useDispatch();
     const [loading, setLoading] = useState(true);
    useEffect(() => {
        const user = getLocalStorage("user")
         const token = getLocalStorage("token")
    
        const refreshToken = getLocalStorage("refreshToken")

        if (user && token && refreshToken) {
            dispatch(authSuccess({
                token,
                refreshToken,
                user
            }))
        } 
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, []);
    
    return <>
          {
                loading ? <PageLoader/> : props.children
          }
    </>;
}


export default Authentication;