
import {createSlice} from "@reduxjs/toolkit"
import { clearLocalStorage,setLocalStorage } from '../../utils/methods';
const initialState = {
    user: {},
    isAuthenticated: false,
    isLoading: false,
    token: null,
    refreshToken: null

}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        authStart: (state) => {
            state.isLoading = true;
            state.error = null;
        }
        ,
        authSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload.user?action.payload.user: state.user;
            state.error = null;
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            setLocalStorage('token', action.payload.token)
            setLocalStorage('refreshToken', action.payload.refreshToken)
            setLocalStorage('user', action.payload.user)
        },
        setOrganisationId: (state, action) => {
            state.user = action.payload.user ? action.payload.user : state.user
            setLocalStorage('user', action.payload.user)
        },
        authFail: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
        ,
        logout: (state) => {
            clearLocalStorage("token");
            clearLocalStorage("refreshToken");
            clearLocalStorage("user");
            state.isAuthenticated = false;
            state.user = {};
            state.error = null;
            
        },
        updateAvatar: (state, action) => {
            state.user.avatar = action.payload;
        }
    },
});
 export const {authStart, authSuccess, setOrganisationId, authFail, logout} = userSlice.actions;
export default userSlice.reducer;