import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Bell as BellIcon } from '../icons/bell';
import Logout from '@mui/icons-material/Logout';
import { Users as UsersIcon } from '../icons/users';
import MenuItemsDropDown from './MenuItemsDropDown';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/reducers/userSlice';
import Settings from '@mui/icons-material/Settings';
import { userRole } from '../utils/constants';
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user.user);
 const handleLogout = () => {
     dispatch(logout())
 }
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            backgroundColor: "#0049af" // Replace with your desired background color
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none',
                color: "white"
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <h1 style={{ color: "white", fontWeight: "bold" }}>Koshi Gas Smart</h1>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Contacts">
          <IconButton sx={{ ml: 1 ,color: "white"}}>
              <UsersIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
          <IconButton sx={{ ml: 1 ,color: "white"}}>
              <Badge
                badgeContent={4}
                color="secondary"
                variant="dot"
              >
                <BellIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
          <MenuItemsDropDown menuItems={[

            {
              icon: <Settings />,
              label: "Setting",
              show: true,
              onClick: () => {
                navigate('/settings')
              }
            },
            {
              icon: <Logout />,
              label: 'Logout',
              show: true,
              onClick: () => {
                handleLogout()
              }
            },
            
          ]}
          >
            <Avatar src={user?.avatar} sx={{ width: 32, height: 32 }}>{user?.email?.substr(0,1)}</Avatar>
          </MenuItemsDropDown>

        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
