// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const vehicleApi = createApi({
    reducerPath: "vehicleApi",
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getAllVehicle: builder.query({
            query: (data) => {
                return({
                    url: `/get-all-vehicle?vehicleNumber=${data?.vehicleNumber}`,
                    method: "GET"
                })
            },
            providesTags: ["vehicles"]
        }),
        create: builder.mutation({
            query: (credentials) => ({
                url: "/create-vehicle",
                method: "POST",
                body: credentials
            }),
            invalidatesTags: ["vehicles"]
        }),
        update: builder.mutation({
            query: (credentials) => {
                // debugger
                return ({
                    url: "/update-vehicle",
                    method: "PUT",
                    body: credentials
                })
            },
        })
        

    }),

})

export const {
    useCreateMutation,
    useLazyGetAllVehicleQuery,
    useUpdateMutation
} = vehicleApi