import {useSelector, useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {useNavigate,useLocation} from "react-router-dom"
import { logout } from './redux/reducers/userSlice';
const AuthGuard = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {children} = props;
    const { isAuthenticated } = useSelector(state => state.user);
    const match = (location.pathname === "/Szs919900") || (location.pathname === "/Fzs918800") ? true : false;

    const dispatch = useDispatch();

    useEffect(() => {
        
        
        if (!isAuthenticated && !match) {
      
       
            dispatch(logout());
            navigate('/login', {replace: true});

        }
    }, [isAuthenticated,match]);

    return (
        <>
            {children}
        </>
    );
}

export default AuthGuard;
