// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react" 
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const bannerApi = createApi({
    reducerPath:"banner",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["banners"],
    endpoints: (builder)=>({
        createBanners:builder.mutation({
            query:(banners)=>({
                url:"/create-banners",
                method:"POST",
                body:banners
            }),
            invalidatesTags:["banners"]
        }),
        getAllBanners:builder.query({
            query:(bannerFor)=>({
                url:`/get-all-banners?bannerFor=${bannerFor}`,
                method:"GET",
            }),
            providesTags:["banners"]
        }),
        deleteBanner:builder.mutation({
            query: ({id, imgPath})=>({
                url: `/delete-banner?bannerId=${id}`,
                method: "DELETE",
                body: {
                    imgPath:imgPath
                }
            }),
            invalidatesTags:["banners"]
        }),
    }),

})

export const {useCreateBannersMutation,useGetAllBannersQuery,useDeleteBannerMutation} = bannerApi