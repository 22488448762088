import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getLocalStorage, setLocalStorage } from "../../utils/methods";
import { authSuccess, logout } from "../reducers/userSlice";
import axios from "axios";
// export const baseUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL    : 'http://localhost:5002'
export const baseUrl = 'http://192.168.1.44:5002'
export const baseQueryHeaders = (headers) => {
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set('Access-Control-Allow-Origin', '*');
    return headers;
}

export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
        return baseQueryHeaders(headers)
    }
})


export const baseQueryWithToken = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().user.token;

        headers.set("Authorization", `Bearer ${token}`);
        return headers
    }
})


export const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQueryWithToken(args, api, extraOptions);
    if (result?.error?.status === 401) {
        const refreshToken = getLocalStorage("refreshToken");
        const refreshTokenResult = await baseQuery({
            url: `/re-auth?refreshToken=${refreshToken}`,
            method: "GET",
        }, api, extraOptions);
        if (refreshTokenResult?.data?.status === 200) {
            const { token, refreshToken } = refreshTokenResult.data;
            api.dispatch(authSuccess({ token, refreshToken }))
            setLocalStorage("token", token);
            setLocalStorage("refreshToken", refreshToken);
            result = await baseQueryWithToken(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }

    }
    return result;
}



export const uploadImagesApi = (file, callback) => {
    const formData = new FormData();
    formData.append("image", file);
    return axios.post(`${baseUrl}/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            callback(percentCompleted);
            console.log(percentCompleted);
        }
    }).then(res => {
        return res;
    }
    ).catch(err => {
        console.log(err);
    }
    )

}