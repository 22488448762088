import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { User as UserIcon } from '../icons/user';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { Users as UsersIcon } from '../icons/users';
import { XCircle as XCircleIcon } from '../icons/x-circle';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Cog as CogIcon } from '../icons/cog';
import { Lock as LockIcon } from '../icons/lock';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { CarRental, Inventory } from '@mui/icons-material';
export const navConfig = [
  {
    href: '/',
    icon: (<ChartBarIcon fontSize="small" />),
    title: 'Dashboard'
  },
  {
    href: '/organisation',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Organisation'
  },
  {
    href: '/dealers',
    icon: (<UserIcon fontSize="small" />),
    title: 'Dealers'
  },
  {
    href: '/admin',
    icon: (<UserIcon fontSize="small" />),
    title: 'Admin'
  },
  {
    href: '/staff',
    icon: (<UserIcon fontSize="small" />),
    title: 'Staff'
  },
  {
    href: '/add-dealer',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'Add Dealers'
  },
  {
    href: '/orders',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'orders'
  },
  {
    href: "/banner",
    icon: (<ViewCarouselIcon fontSize="small" />),
    title: "Banner"
  },
  {
    href: "/gallery",
    icon: (<ViewCarouselIcon fontSize="small" />),
    title: "Gallery"
  },
  {
    href: '/vehicles',
    icon: (<CarRental fontSize="small" />),
    title: "Vehicle"
  },
  {
    href: '/inventory',
    icon: (<Inventory fontSize="small" />),
    title: "Inventory"
  }
];
export const navConfigAdmin = [
  {
    href: '/dealers',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Dealers'
  },
  {
    href: '/staff',
    icon: (<UserIcon fontSize="small" />),
    title: 'Users'
  },
  {
    href: '/add-dealer',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'Add Dealers'
  },
  {
    href: '/orders',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'orders'
  },
  {
    href: "/gallery",
    icon: (<ViewCarouselIcon fontSize="small" />),
    title: "Gallery"
  },
  {
    href: '/inventory',
    icon: (<Inventory fontSize="small" />),
    title: "Inventory"
  }
];
export const navConfigStaff = [
  {
    href: '/dealers',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Dealers'
  },
  {
    href: '/add-dealer',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'Add Dealers'
  },
  {
    href: '/orders',
    icon: (<UserAddIcon fontSize="small" />),
    title: 'orders'
  },
  {
    href: "/gallery",
    icon: (<ViewCarouselIcon fontSize="small" />),
    title: "Gallery"
  },
  {
    href: '/inventory',
    icon: (<Inventory fontSize="small" />),
    title: "Inventory"
  }
]