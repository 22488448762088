// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./helper";
// Define a service using a base URL and expected endpoints
export const inventoryApi = createApi({
    reducerPath: "inventoryApi",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["inventory"],
    endpoints: (builder) => ({
        createInventory: builder.mutation({
            query: (credentials) => {
                return ({
                    url: "/create-inventory",
                    method: "POST",
                    body: credentials
                })
            },
            invalidatesTags: ["inventory"]
        }),
        updateInventory: builder.mutation({
            query: (credentials) => {
                return ({
                    url: "/update-inventory",
                    method: "put",
                    body: credentials
                })
            },
            invalidatesTags: ["inventory"]
        }),
        getAllInventory: builder.query({
            query: (credentials) => ({
                url: `/get-inventory`,
                method: "GET",
            }),
            providesTags: ["inventory"]
        }),
        deleteInventory: builder.mutation({
            query: (id) => ({
                url: `/delete-inventory?id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["inventory"]
        }),
    }),

})

export const {
    useLazyGetAllInventoryQuery,
    useCreateInventoryMutation,
    useUpdateInventoryMutation,
    useDeleteInventoryMutation

} = inventoryApi